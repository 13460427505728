.root {
	border: 1px solid currentColor;
	padding: 0 4px;
	color: inherit;
	border-radius: 4px;
	text-decoration: none;
	display: inline-block;
	background-color: rgba(160, 160, 160, 0.1);
}
.root:hover {
	background-color: rgba(160, 160, 160, 0.3);
}
.icon {
	display: inline-block;
	width: 16px;
	height: 16px;
	vertical-align: middle;
	margin-top: -3px;
	margin-right: 2px;
	background: light-dark(black, white);
	mask-size: contain;
}
