.root {
	display: flex;
	flex-wrap: wrap;
	margin: 0;
	padding: 0;
}
.root > li {
	list-style: none;
	margin: 0 8px 8px 0;
}
