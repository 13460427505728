.common_noMarginTop__YmZuz {
	margin-top: 0;
}

.common_sectionHeading__W9h3T {
}
.common_sidebarTitle__rN0fm {
	font-size: 19px;
	position: relative;
	margin: 0;
}
.common_center__DKx2U {
	text-align: center;
}

.ArticleWrapper_root__EqYxg {
	display: grid;
	grid-template:
		"info-me content" auto
		"info-post content" auto
		"toc content" 96px
		"toc content" auto
		"toc children" 1fr
		"toc comments" auto
		"toc moreReading" auto
		/ 256px 1fr;
	padding: 0 64px;
	column-gap: 16px;
	align-items: flex-start;
}
.ArticleWrapper_displayContent__m_YEC {
	display: contents;
}
.ArticleWrapper_moreReading__VBFsk {
	grid-area: moreReading;
}
.ArticleWrapper_children__fNyHY {
	grid-area: children;
}
.ArticleWrapper_comments__ifxro {
	grid-area: comments;
}
.ArticleWrapper_meInfo__XrMpc {
	padding-top: 32px;
	grid-area: info-me;
	align-self: stretch;
}
.ArticleWrapper_postInfo__Vesev {
	grid-area: info-post;
}
.ArticleWrapper_tocWrapper__X5CD7 {
	grid-area: toc;
	align-self: stretch;
}
.ArticleWrapper_markdown__7o_b1 {
	grid-area: content;
	min-width: 0;
	align-self: stretch;
}
.ArticleWrapper_rightSide__FLl14 {
	display: contents;
}
.ArticleWrapper_last__P_Of7 {
	grid-area: last;
}
.ArticleWrapper_childLink__rxnQa {
	color: var(--is-light-mode, black) var(--is-dark-mode, #eeeeee);
	color: light-dark(black, #eeeeee);
}
.ArticleWrapper_childLink__rxnQa:visited {
	color: var(--is-light-mode, #606060) var(--is-dark-mode, #bbbbbb);
	color: light-dark(#606060, #bbbbbb);
}
.ArticleWrapper_sectionHeading__HLQzz {
}
.ArticleWrapper_commentHeading__VRclN {
	margin-left: 16px;
	margin-right: 16px;
}
.ArticleWrapper_markdown__7o_b1 {
	display: flow-root;
	padding: 0 16px 16px;
}

.ArticleWrapper_markdown__7o_b1 figure[data-rehype-pretty-code-figure] {
	margin: 0 -16px;
}

.ArticleWrapper_markdown__7o_b1 figure[data-rehype-pretty-code-figure] pre {
	overflow: auto;
	max-height: 90vh;
	margin: 0;
}

.ArticleWrapper_markdown__7o_b1 figure[data-rehype-pretty-code-figure] code {
	counter-reset: line;
	padding: 8px 16px;
}

.ArticleWrapper_markdown__7o_b1 figure[data-rehype-pretty-code-figure] code[data-line-numbers]>[data-line]::before {
	counter-increment: line;
	content: counter(line);

	/* Other styling */
	display: inline-block;
	width: 1rem;
	margin-right: 2rem;
	text-align: right;
	color: gray;
}

.ArticleWrapper_markdown__7o_b1 figure[data-rehype-pretty-code-figure] code[data-line-numbers-max-digits="2"]>[data-line]::before {
	width: 2rem;
}

.ArticleWrapper_markdown__7o_b1 figure[data-rehype-pretty-code-figure] code[data-line-numbers-max-digits="3"]>[data-line]::before {
	width: 3rem;
}
.ArticleWrapper_markdown__7o_b1 figure[data-rehype-pretty-code-figure] code[data-theme*=" "],
.ArticleWrapper_markdown__7o_b1 figure[data-rehype-pretty-code-figure] code[data-theme*=" "] span {
  color: var(--is-light-mode, var(--shiki-light)) var(--is-dark-mode, var(--shiki-dark));
  background-color: var(--is-light-mode, var(--shiki-light-bg)) var(--is-dark-mode, var(--shiki-dark-bg));
}
@supports (color: light-dark(red, red)) {
.ArticleWrapper_markdown__7o_b1 figure[data-rehype-pretty-code-figure] code[data-theme*=" "],
.ArticleWrapper_markdown__7o_b1 figure[data-rehype-pretty-code-figure] code[data-theme*=" "] span {
  color: light-dark(var(--shiki-light), var(--shiki-dark));
  background-color: light-dark(var(--shiki-light-bg), var(--shiki-dark-bg));
}
}
.ArticleWrapper_markdown__7o_b1 img {
	max-width: 100%;
	aspect-ratio: auto;
	height: auto;
}

.ArticleWrapper_rootShort__37da8 {
	grid-template:
		"info-me right" auto
		"info-post right" auto
		"toc right" 1fr
		"toc moreReading" auto
		/ 256px 1fr;

}
.ArticleWrapper_rootShort__37da8 .ArticleWrapper_rightSide__FLl14 {
	grid-area: right;
	display: initial;
	min-width: 0;
}
@media screen and (max-width: 1280px) {
	.ArticleWrapper_root__EqYxg {
		padding: 0 8px;
	}
}
@media screen and (max-width: 1023px) {
	.ArticleWrapper_root__EqYxg {
		grid-template:
			"info-me info-post" auto
			"toc toc" auto
			"right right" auto
			"moreReading moreReading" auto
			/ 256px 1fr;
	}
	.ArticleWrapper_rightSide__FLl14 {
		grid-area: right;
		display: initial;
		min-width: 0;
	}
	.ArticleWrapper_toc__58nxQ {
		margin-bottom: 16px;
	}
}
@media screen and (max-width: 480px) {
	.ArticleWrapper_root__EqYxg {
		grid-template:
			"info-me" auto
			"info-post" auto
			"toc" auto
			"right" auto
			"moreReading" auto
			/ 1fr;
	}
}

.ArticleInfo_root__Jxe2c {
	padding: 4px 0 8px 8px;
	font-size: 0.8em;
}

.ArticleInfo_title__2W7pa {
}
.ArticleInfo_listItem__sxZZ1 {
	margin: 0.5em 0;
}
.ArticleInfo_links__nhWNd {
}

.Tag_root__mn_Zf {
	border: 1px solid currentColor;
	padding: 0 4px;
	color: inherit;
	border-radius: 4px;
	text-decoration: none;
	display: inline-block;
	background-color: rgba(160, 160, 160, 0.1);
}
.Tag_root__mn_Zf:hover {
	background-color: rgba(160, 160, 160, 0.3);
}
.Tag_icon__STN4O {
	display: inline-block;
	width: 16px;
	height: 16px;
	vertical-align: middle;
	margin-top: -3px;
	margin-right: 2px;
	background: var(--is-light-mode, black) var(--is-dark-mode, white);
	background: light-dark(black, white);
	-webkit-mask-size: contain;
	        mask-size: contain;
}

.TagList_root__vh84f {
	display: flex;
	flex-wrap: wrap;
	margin: 0;
	padding: 0;
}
.TagList_root__vh84f > li {
	list-style: none;
	margin: 0 8px 8px 0;
}

